<template>
  <div class="overlay">
    <page-title>{{ i18n.MENU.furniture }}</page-title>
    <div class="page-width" v-if="model === ''">
      <div class="furniture__empty">
        {{ i18n.FURNITURE.SELECT_MODEL }}
      </div>
    </div>
    <div class="page-width" v-if="model">
      <div class="furniture">
        <div class="furniture__empty" v-if="items.length === 0">
          {{ i18n.FURNITURE.EMPTY_FURNITURE }}
        </div>
        <label
          :class="[
            'furniture__item',
            {
              'furniture__item--current':
                item.id ===
                (house === 'inside' ? furnitureInside.id : furniture.id)
            },
            { 'furniture__item--active': item.active === '1' }
          ]"
          @click.prevent="
            setFurniture(
              item,
              item.id ===
                (house === 'inside' ? furnitureInside.id : furniture.id) ||
                item.active === '0'
            )
          "
          v-for="(item, idx) in items"
          :key="`furniture__${idx}`"
        >
          <span class="furniture__bg">
            <img class="furniture__img" :src="item.image_png" />
          </span>
          <div class="furniture__title">{{ item.title }}</div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/page-title/page-title.vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import { API } from '@/constants/api';
import { setCode } from '@/utils/code';

export default {
  data() {
    return {
      projection: 'outside',
      items: []
    };
  },
  components: {
    PageTitle
  },
  computed: {
    ...mapGetters('session', ['i18n']),
    ...mapGetters('config', [
      'shape',
      'model',
      'glass',
      'color',
      'furniture',
      'options',
      'inox',
      'view',
      'mirrored',
      'black',
      'furnitureInside',
      'house'
    ])
  },
  metaInfo() {
    return {
      title: this.i18n.MENU.furniture,
      meta: [
        { vmid: 'description', property: 'description', content: 'Фурнітура' },
        { vmid: 'og:title', property: 'og:title', content: 'Фурнітура' },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: 'Фурнітура'
        }
      ]
    };
  },
  methods: {
    setProjection(value) {
      this.projection = value;
    },
    getFurniture() {
      axios
        .get(API.GET_FURNITURE, { params: { model: this.model.id } })
        .then(response => {
          if (this.color.outside.category === '2') {
            this.items = response.data
              .filter(item => item.id !== '20')
              .filter(item => item.id !== '21');
          } else {
            this.items = response.data;
          }
        });
    },
    async setFurniture(item, isActive) {
      if (isActive) {
        return false;
      }
      let response;
      const {
        shape,
        model,
        glass,
        color,
        // furniture,
        options,
        inox,
        view,
        mirrored,
        black
      } = this;
      const moldingView = inox?.url.includes('molding')
        ? inox?.url.split('-')[1]
        : null;
      try {
        response = await axios.get(API.GET_CODE_FROM_CONFIG, {
          params: {
            shape: shape.id,
            model: model.id,
            glass: glass.id,
            colour_outside: color.outside.id,
            colour_inside: color.inside.id,
            furniture: item.id,
            options: options.id,
            inox: inox.id,
            molding: moldingView,
            view: view.id,
            mirrored: mirrored.id,
            black
          }
        });
      } catch (e) {
        console.error(e);
      }
      if (this.house === 'outside') {
        await setCode({
          code: response.data.code,
          store: this.$store,
          router: this.$router
        });
        await this.$store.dispatch('config/CONFIG_SET_FURNITURE', item);
      }
    }
  },
  created() {
    this.getFurniture();
  }
};
</script>

<style lang="scss">
.page-width {
  padding: 30px;
}
.furniture {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #5c646b;
  &__empty {
    color: #ffffff;
  }
  &__item {
    flex: 1 0 50%;
    max-width: 50%;
    padding: 10px;
    opacity: 0.5;
    &--active {
      cursor: pointer;
      opacity: 1;
    }
    &--active:hover,
    &--current {
      .furniture__bg {
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          border: 8px solid #c70552;
          z-index: 2;
        }
      }
    }
  }
  &__bg {
    display: block;
    position: relative;
    height: 0;
    padding-bottom: 100%;
  }
  &__img {
    left: 0;
    top: 0;
    width: 100%;
    position: relative;
  }
  &__title {
    text-align: center;
    font: 15px/18px 'ProximaNovaRegular', sans-serif;
    color: #ffffff;
    margin-top: 16px;
  }
}
</style>

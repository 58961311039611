var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overlay"},[_c('page-title',[_vm._v(_vm._s(_vm.i18n.MENU.furniture))]),(_vm.model === '')?_c('div',{staticClass:"page-width"},[_c('div',{staticClass:"furniture__empty"},[_vm._v(" "+_vm._s(_vm.i18n.FURNITURE.SELECT_MODEL)+" ")])]):_vm._e(),(_vm.model)?_c('div',{staticClass:"page-width"},[_c('div',{staticClass:"furniture"},[(_vm.items.length === 0)?_c('div',{staticClass:"furniture__empty"},[_vm._v(" "+_vm._s(_vm.i18n.FURNITURE.EMPTY_FURNITURE)+" ")]):_vm._e(),_vm._l((_vm.items),function(item,idx){return _c('label',{key:("furniture__" + idx),class:[
          'furniture__item',
          {
            'furniture__item--current':
              item.id ===
              (_vm.house === 'inside' ? _vm.furnitureInside.id : _vm.furniture.id)
          },
          { 'furniture__item--active': item.active === '1' }
        ],on:{"click":function($event){$event.preventDefault();_vm.setFurniture(
            item,
            item.id ===
              (_vm.house === 'inside' ? _vm.furnitureInside.id : _vm.furniture.id) ||
              item.active === '0'
          )}}},[_c('span',{staticClass:"furniture__bg"},[_c('img',{staticClass:"furniture__img",attrs:{"src":item.image_png}})]),_c('div',{staticClass:"furniture__title"},[_vm._v(_vm._s(item.title))])])})],2)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }